<template>
  <div class="bg-white">
    <a-tabs type="card" :tabBarGutter="10">
      <a-tab-pane v-for="item in tabList" :key="item.id" :tab="item.name">
        <component :is="item.component"></component>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import {
  HomeAdvert,
  MallAdvert,
  ShopAdvert,
  ShopDrinkAdvert,
  DrinkOpenAdvert,
  MallSearchAdvert,
  ShopSearchAdvert,
  NewsAdvert,
  OpenAdvert,
  GiftCardAdvert,
  GiftMallAdvert,
  // AnimationAdvert
} from "./adverts"
export default {
  data() {
    return {
      tabList: [
        {
          id: 1,
          name: "开屏广告",
          component: OpenAdvert,
        },
        {
          id: 2,
          name: "首页轮播图",
          component: HomeAdvert,
        },
        {
          id: 3,
          name: "首页NEWS",
          component: NewsAdvert,
        },
        {
          id: 4,
          name: "门店页广告",
          component: ShopAdvert,
        },
        {
          id: 5,
          name: "门店好茶广告",
          component: ShopDrinkAdvert,
        },
        {
          id: 11,
          name: "门店好茶弹窗",
          component: DrinkOpenAdvert,
        },
        {
          id: 6,
          name: "商城页广告",
          component: MallAdvert,
        },
        {
          id: 7,
          name: "门店热门搜索",
          component: ShopSearchAdvert,
        },
        {
          id: 8,
          name: "商城热门搜索",
          component: MallSearchAdvert,
        },
        {
          id: 9,
          name: "个人页礼品卡",
          component: GiftCardAdvert,
        },
        {
          id: 10,
          name: "礼品卡商城背景图",
          component: GiftMallAdvert,
        },
        // {
        //   id: 7,
        //   name: "动效",
        //   component: AnimationAdvert,
        // },
      ],
    }
  },
  methods: {
    callback(key) {
      console.log(key)
    },
  },
}
</script>
<style>

.card-container {
  background: #f5f5f5;
  overflow: hidden;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
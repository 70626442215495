<template>
  <div class="p-10">
    <div class="flex items-center">
      <span style="color: #f5222d;" class="mr-1">*</span>顶部广播：
      <a-input class="ml-2 flex-1" v-model="formState.notice"></a-input>
    </div>
    <a-divider />

    <div class="flex mt-5">
      <div>
        <span style="color: #f5222d;" class="mr-1">*</span>广告卡片：
      </div>
      <div class="flex-1 ml-5">
        <div id="shop-advert-list">
          <div v-for="(item,i) in advertList" :key="item.local_id">
            <div class="flex items-center">
              <upload-image class="mr-5" 
                  :type="isShop? '11': '10'" 
                  :value.sync="item.image_path"
                  @remove="handlerRemove(i)" />
              <jump-type class="flex-1" shopType="1" type="24" :data="item" />
            </div>
          </div>
        </div>
        <a-button class="mt-2"
          v-if="advertList.length < MAX_COUNT"
          @click="handlerAdd">新增广告卡片</a-button>
      </div>
    </div>
    <a-divider />

    <div style="text-align:center;">
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"
import { getShopAdvertList, editShopAdvert } from "@/api/advert"
import { getAdvertList, editAdverts } from "@/api/shop"
import { requiredRule } from "@/hooks/use-form-rules"
import Sortable from 'sortablejs'
export default {
  components: { UploadImage, JumpType },
  props: {
    isShop: {
      type: Boolean,
      defalut: false,
    },
  },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
      formState: { notice: "" },

      MAX_COUNT: 8,
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      if (this.isShop) {
        const { data, code } = await getAdvertList()
        if (code === 0) {
          this.formState.notice = data.notice
          let list = data.list.map(el=>{
            el.local_id = el.id
            return el
          })
          while(list.length < 2){
            list.push({
              id: 0,
              local_id: Math.round(Math.random()*1000000000),
              image_path: "",
              edit_jump_type: 0,
              edit_jump_data: "",
            })
          }
          this.advertList = list
        }
      } else {
        const { data, code } = await getShopAdvertList()
        if (code === 0) {
          this.formState.notice = data.notice
          this.advertList = data.list
        }
      }
    },

    onSubmit() {
      if(this.advertList.some(el=>{
        return !el.image_path
      })){
        this.$message.info("请完善分类数据");
        return
      }
      
      let sort = 1
      const params = this.advertList.map(el=>{
        el.sort = sort++
        return el
      })

      if (this.isShop) {
        editAdverts({
          notice: this.formState.notice,
          data: JSON.stringify(params),
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("保存成功！")
          }
        })
      } else {
        editShopAdvert({
          notice: this.formState.notice,
          data: JSON.stringify(params),
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("保存成功！")
          }
        })
      }
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          local_id: Math.round(Math.random()*1000000000),
          image_path: "",
          sort: 1,
          title: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('shop-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },
  },
}
</script>

<style lang="less">
.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>
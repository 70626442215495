<template>
  <div class="p-10"> 
    <a-form-model layout="inline"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }"
        ref="searchRef">
      <div id="news-advert-list">
        <div class="flex content-class" :class="i==advertList.length-1?'last':''"
            v-for="(item,i) in advertList" :key="item.id">
          <a-form-model-item :rules="requiredRule" prop="image_path" label="图片">
            <upload-image isDelete type="10" @remove="handlerRemove(i)" :value.sync="item.image_path" />
          </a-form-model-item>
          <div style="flex:1; max-width:900px;">
            <a-form-model-item :label="`标题`" :rules="requiredRule" 
                :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
                style="width:100%;">
              <div class="flex items-center" style="width:100%; max-width:800px;">
                <a-input style="flex:2;" v-model="item.title" />
                <span class="ml-8">副标题：</span>
                <a-input style="flex:3;" v-model="item.subtitle" />
              </div>
            </a-form-model-item>
            <a-form-model-item prop="edit_jump_data" :rules="requiredRule" label="跳转"
                :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
                class="mt-2" style="width:100%;">
              <jump-type type="134" :data="item" style="width:100%; max-width:800px;" />
            </a-form-model-item>
          </div>
        </div>
      </div>
    </a-form-model>
    <a-button class="ml-12 mt-2" type="primary"
        v-if="advertList.length < MAX_COUNT"
        @click="handlerAdd">新增广告图</a-button>
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"
import { getNewsAdvertList, editNewsAdvert } from "@/api/advert"
import { requiredRule } from "@/hooks/use-form-rules"
import Sortable from 'sortablejs'
export default {
  components: { UploadImage, JumpType },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],

      MAX_COUNT: 8,
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getNewsAdvertList()
      if (code === 0) {
        this.advertList = data.list
      }
    },

    onSubmit() {
      if(this.advertList.some(el=>{
        return !el.image_path || !el.title || !el.subtitle
      })){
        this.$message.info("请完善分类数据");
        return
      }
      
      let sort = 1
      const params = this.advertList.map(el=>{
        el.sort = sort++
        return el
      })
      editNewsAdvert({ data: JSON.stringify(params) }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          image_path: "",
          // sort: 1,
          title: "",
          subtitle: ""
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('news-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },
  },
}
</script>

<style scoped>
.content-class {
  padding-top: 30px;
  border-bottom: 1px solid #f2f2f2;
}
.content-class.last{
  border-bottom: none;
}


.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  /* border-bottom: 10px solid rgb(194, 194, 194); */
  margin-bottom: 20px;
}
</style>
<template>
  <div>
    <a-form-model
      style="margin-top:30px"
      layout="horizontal"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 20 }"
      ref="searchRef"
      :model="stateForm"
    >
      <a-form-model-item label="图片">
        <upload-image type="10" :value.sync="stateForm.image_path" />
        （建议尺寸：750*600）
      </a-form-model-item>
      <!-- <a-form-model-item label="跳转">
        <jump-type :data="stateForm" type="0134" style="width:700px;" />
      </a-form-model-item> -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" style="margin-left: 10px;">保存</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"

import { getGiftMallAdvert, editGiftMallAdvert } from "@/api/advert"
// import JumpType from "../components/jump-type.vue"

export default {
  components: { UploadImage },
  data() {
    return {
      typeList: [],
      stateForm: {
        id: 0,
        sort: 1,
        title: "无",
        image_path: "",
        edit_jump_type: 0,
        edit_jump_data: {
          // url: "https://www.xxx.com",
        },
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getGiftMallAdvert()
      if (code === 0) {
        if(data.list.length){
          this.stateForm = data.list[0]
        }
      }
    },

    onSubmit() {
      // this.stateForm.id = 0
      editGiftMallAdvert({ data: JSON.stringify([this.stateForm]) }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },
  },
}
</script>

<style>
</style>
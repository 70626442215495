<template>
  <div class="p-10">
    <div class="flex">
      <div>商城热门搜索词：</div>
      <div class="ml-6">
        <div id="mall-advert-list">
          <div v-for="(item,i) in advertList" :key="item.id" class="mb-2">
            <div class="flex items-center">
              <a-input style="width:400px;" v-model="item.title" placeholder="请输入搜索词"></a-input>
              <a-icon style="color:#f00;margin:10px;" type="delete" class="cursor-pointer"
                  @click="handlerRemove(i)" />
              <a-icon style="padding:10px;" type="drag" class="cursor-pointer" />
            </div>
          </div>
        </div>
        <a-button class="mt-2"
          v-if="advertList.length < MAX_COUNT"
          @click="handlerAdd">新增</a-button>
      </div>
    </div>
      
    <a-divider />
    <div style="text-align:center;">
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import { getMallSearchAdvert, editMallSearchAdvert } from "@/api/advert"
import { requiredRule } from "@/hooks/use-form-rules"
import Sortable from 'sortablejs'

export default {
  components: { },
  data() {
    return {
      requiredRule: requiredRule,
      advertList: [],
      notice: "",

      MAX_COUNT: 10,
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getMallSearchAdvert()
      if (code === 0) {
        this.advertList = data.list
      }
    },

    onSubmit() {
      const hasEmpty = this.advertList.some(el=>{
        return !(el.title || "").trim()
      })
      if(hasEmpty){
        this.$message.warning("关键词不能为空")
        return
      }
      
      let sort = 1
      const params = this.advertList.map(el=>{
        el.title = el.title.trim()
        el.sort = sort++
        return el
      })
      
      editMallSearchAdvert({
        data: JSON.stringify(params),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          // edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          sort: 1,
          title: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('mall-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },

  },
}
</script>

<style lang="less">
.advert-title {
  position: absolute;
  top: 7px;
  left: 80px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>
<template>
  <div class="p-10">
    <div class="flex items-center">
      <span style="color: #f5222d;" class="mr-1">*</span>顶部广播：
      <a-input class="ml-2 flex-1" v-model="notice"></a-input>
    </div>
    <a-divider />
    <div class="flex mt-5">
      <div>
        <span style="color: #f5222d;" class="mr-1">*</span>顶部轮播图：
      </div>
      <div class="ml-6">
        <div id="mall-advert-list">
          <div v-for="(item,i) in advertList" :key="item.id">
            <div class="flex items-center" style="min-width:600px;">
              <upload-image class="mr-6" 
                  type="10" :value.sync="item.image_path" @remove="handlerRemove(i)" />
              <jump-type class="flex-1" style="max-width:800px;" 
                  type="34" :data="item"/>
            </div>
          </div>
        </div>
        <a-button class="mt-2"
          v-if="advertList.length < MAX_COUNT"
          @click="handlerAdd"
        >新增轮播图</a-button>
      </div>
    </div>
      
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"

import { getMallAdvertList, editMallAdvert } from "@/api/advert"
import { requiredRule } from "@/hooks/use-form-rules"
import Sortable from 'sortablejs'

export default {
  components: { UploadImage, JumpType },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
      notice: "",

      MAX_COUNT: 5,
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getMallAdvertList()
      if (code === 0) {
        this.advertList = data.list
        this.notice = data.notice
      }
    },

    onSubmit() {
      if(this.advertList.some(el=>{
        return !el.image_path
      })){
        this.$message.info("请完善分类数据");
        return
      }

      let sort = 1
      const params = this.advertList.map(el=>{
        el.sort = sort++
        return el
      })
      
      editMallAdvert({
        notice: this.notice,
        data: JSON.stringify(params),
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          image_path: "",
          sort: 1,
          title: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('mall-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },

  },
}
</script>

<style lang="less">
.advert-title {
  position: absolute;
  top: 7px;
  left: 80px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>
<template>
  <div class="p-10"> 
    <a-form-model layout="inline"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 18 }"
      ref="searchRef"
    >
      <div id="gift-advert-list">
        <div class="flex content-class" :class="i==advertList.length-1?'last':''"
            v-for="(item,i) in advertList" :key="item.local_id">
          <a-form-model-item :rules="requiredRule" prop="image_path" label="图片">
            <upload-image isDelete type="10" @remove="handlerRemove(i)" :value.sync="item.image_path" />
          </a-form-model-item>
        </div>
      </div>
        
    </a-form-model>
    <a-button class="ml-12 mt-2" type="primary"
        v-if="advertList.length < MAX_COUNT"
        @click="handlerAdd">新增礼品卡广告图</a-button>
    <a-divider />
    <div style="text-align:center;">
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
// import JumpType from "../components/jump-type.vue"
import { getGiftCardAdvertList, editGiftCardAdvert } from "@/api/advert"
import { requiredRule } from "@/hooks/use-form-rules"
import Sortable from 'sortablejs'
export default {
  components: { UploadImage },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      // typeList: [],
      advertList: [],

      MAX_COUNT: 5,
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getGiftCardAdvertList()
      if (code === 0) {
        this.advertList = data.list.map(el=>{
          el.local_id = el.id
          return el
        })
      }
    },

    onSubmit() {
      if(this.advertList.some(el=>{
        return !el.image_path
      })){
        this.$message.info("请完善分类数据");
        return
      }

      let sort = 1
      const params = this.advertList.map(el=>{
        el.sort = sort++
        el.title = ""
        el.edit_jump_type = 0
        return el
      })
      editGiftCardAdvert({ 
        notice: "1", // 随便填个啥（因为接口需要 但没这个功能需求）
        data: JSON.stringify(params)
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          id: 0,
          local_id: Math.round(Math.random()*1000000000),
          image_path: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('gift-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },
  },
}
</script>

<style scoped>
.content-class {
  padding-top: 30px;
  border-bottom: 1px solid #f2f2f2;
}
.content-class.last{
  border-bottom: none;
}


.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  /* border-bottom: 10px solid rgb(194, 194, 194); */
  margin-bottom: 20px;
}
</style>
<template>
  <div class="p-10">
    <a-form-model layout="horizontal"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 18 }"
    >
      <div id="home-advert-list">
        <div class="home-advert-layout" :class="i==advertList.length-1?'last':''"
            v-for="(item,i) in advertList" :key="item.local_id">
          <a-form-model-item :rules="requiredRule" prop="image_path" label="图片">
            <upload-image type="10" @remove="handlerRemove(i)" :value.sync="item.image_path" />
          </a-form-model-item>
          <div class="home-advert-right-layout" style="flex:1; max-width:800px;">
            <a-form-model-item :label="`文案`" 
                :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-input style="width:100%; max-width:800px;" v-model="item.title" />
            </a-form-model-item>
            <a-form-model-item :rules="requiredRule" prop="edit_jump_data" label="跳转" class="mt-2"
                :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <jump-type :data="item" type="0134" style="width:100%; max-width:800px;" />
            </a-form-model-item>
          </div>
        </div>
      </div>
    </a-form-model>
    <a-button v-if="advertList.length < 5"
        @click="handlerAdd"
        style="margin-left:70px;">新增广告图</a-button>
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"
import Sortable from 'sortablejs'

import { getHomeAdvertList, editHomeAdvert } from "@/api/advert"
import { requiredRule } from "@/hooks/use-form-rules"

export default {
  components: { UploadImage, JumpType },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
    }
  },
  async mounted() {
    await this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getHomeAdvertList()
      if (code === 0) {
        this.advertList = data.list.map(el=>{
          el.local_id = el.id
          return el
        })
      }
    },

    onSubmit() {
      if(this.advertList.some(el=>{
        return !el.image_path || !el.title
      })){
        this.$message.info("请完善分类数据");
        return
      }

      let sort = 1
      const params = this.advertList.map(el=>{
        el.sort = sort++
        return el
      })
      editHomeAdvert({ data: JSON.stringify(params) }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < 5) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          local_id: Math.round(Math.random()*1000000000),
          image_path: "",
          // sort: 1,
          title: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('home-advert-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },

  },
}
</script>

<style lang="less">
.home-advert-layout {
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 40px;
  &.last{
    border-bottom: none;
    margin-bottom: 0;
  }
  .home-advert-right-layout{
    padding-bottom: 0px;
  }
}

.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0px;
}
</style>
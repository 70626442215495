import ajax from '@/utils/ajax.js'

/**
 * 获取开屏广告列表
 * @param {*} params 
 * @returns 
 */
export function getOpenAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition1',params)
}
/**
 * 编辑开屏广告列表
 * @param {*} params 
 * @returns 
 */
 export function editOpenAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition1',params)
}


/**
 * 获取首页广告列表
 * @param {*} params 
 * @returns 
 */
 export function getHomeAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition2',params)
}


/**
 * 获取首页广告列表
 * @param {*} params 
 * @returns 
 */
 export function editHomeAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition2',params)
}


/**
 * 获取首页广告列表
 * @param {*} params 
 * @returns 
 */
 export function getNewsAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition3',params)
}


/**
 * 编辑首页广告列表
 * @param {*} params 
 * @returns 
 */
 export function editNewsAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition3',params)
}

/**
 * 获取门店广告列表
 * @param {*} params 
 * @returns 
 */
 export function getShopAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition4',params)
}
/**
 * 编辑门店广告列表
 * @param {*} params 
 * @returns 
 */
export function editShopAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition4',params)
}

/**
 * 获取门店弹窗广告列表：好茶
 * @param {*} params 
 * @returns 
 */
export function getDrinkOpenAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition14',params)
}
/**
 * 编辑门店弹窗广告列表：好茶
 * @param {*} params 
 * @returns 
 */
 export function editDrinkOpenAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition14',params)
}

/**
 * 获取门店广告列表：好茶
 * @param {*} params 
 * @returns 
 */
 export function getShopDrinkAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition13',params)
}
/**
 * 编辑门店广告列表：好茶
 * @param {*} params 
 * @returns 
 */
export function editShopDrinkAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition13',params)
}


/**
 * 获取商城广告列表
 * @param {*} params 
 * @returns 
 */
 export function getMallAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition5',params)
}

/**
 * 编辑商城广告列表
 * @param {*} params 
 * @returns 
 */
 export function editMallAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition5',params)
}

/**
 * 礼品卡广告列表
 * @param {*} params 
 * @returns 
 */
export function getGiftCardAdvertList(params) {
  return ajax.post('/XcxAd/getListPosition9',params)
}

/**
 * 编辑礼品卡广告列表
 * @param {*} params 
 * @returns 
 */
export function editGiftCardAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition9',params)
}

/**
 * 热门搜索-门店
 * @param {*} params 
 * @returns 
 */
export function getShopSearchAdvert(params) {
  return ajax.post('/XcxAd/getListPosition10',params)
}
export function editShopSearchAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition10',params)
}


/**
 * 热门搜索-商城
 * @param {*} params 
 * @returns 
 */
export function getMallSearchAdvert(params) {
  return ajax.post('/XcxAd/getListPosition11',params)
}
export function editMallSearchAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition11',params)
}


/**
 * 礼品卡商城 背景图
 * @param {*} params 
 * @returns 
 */
export function getGiftMallAdvert(params) {
  return ajax.post('/XcxAd/getListPosition12',params)
}
export function editGiftMallAdvert(params) {
  return ajax.post('/XcxAd/editDataPosition12',params)
}


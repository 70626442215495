<template>
    <div>
        <a-form-model
            style="margin-top:30px"
            layout="horizontal"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 20 }"
            ref="searchRef"
            :model="stateForm"
        >
            <a-form-model-item label="图片">
                <upload-image type="10" :value.sync="stateForm.image_path" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <!-- <a-button>取消</a-button> -->
                <a-button type="primary" @click="onSubmit" style="margin-left: 10px;"
                    >保存</a-button
                >
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
    import UploadImage from '@/components/upload-image'
    import { getOpenAdvertList, editOpenAdvert } from '@/api/advert'
    export default {
        components: { UploadImage },
        data() {
            return {
                radioStyle: {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px'
                },
                typeList: [],
                stateForm: {
                    id: 0,
                    sort: 2,
                    image_path: '',
                    edit_jump_type: 1,
                    edit_jump_data: {
                        // url: "https://www.xxx.com",
                    }
                }
            }
        },
        mounted() {
            this.initData()
        },
        methods: {
            async initData() {
                const { data, code } = await getOpenAdvertList()
                if (code === 0) {
                    this.stateForm = data.list[0]
                }
            },

            onSubmit() {
                this.stateForm.id = 0
                editOpenAdvert({ data: JSON.stringify([this.stateForm]) }).then(res => {
                    if (res.code === 0) {
                        this.$message.success('保存成功！')
                    }
                })
            }
        }
    }
</script>

<style></style>
